module.exports = exports = {
  free: {
    items: [
      {
        description: 'Single user',
        icon: 'fa-user'
      },
      { description: 'Up to 100 parts' },
      { description: 'Up to 3 products' },
      { description: 'Unlimited storage locations' },
      { description: 'Unlimited documents' }
    ]
  },
  maker: {
    items: [
      { description: 'Single user', icon: 'fa-user' },
      {
        description: 'Multiple Local Workspaces',
        tooltip:
          'You can have as many local databases as you need.<br/><a class="mt-1" href="https://docs.bomist.com/features/workspaces" target="_blank">Learn more</a>',
        class: 'text-bold'
      },
      { description: 'Up to 1000 parts' },
      { description: 'Unlimited products' },
      { description: 'Purchasing' },
      { description: 'Custom suppliers and quotes' },
      {
        description: 'Barcode labels (print & scan)',
        tooltip:
          'Print barcode labels straight from the app or scan vendor barcodes for quick stock adjustments and/or parts lookup.<br/><a class="mt-1" href="https://docs.bomist.com/features/barcodes" target="_blank">Learn more</a>'
      },
      {
        description: 'REST API',
        tooltip:
          'Access and process your data the way you want through a locally served REST API.<br/><a class="mt-1" href="https://docs.bomist.com/features/api" target="_blank">Learn more</a>'
      },
      { description: 'Part alternates' },
      { description: 'Global history' }
    ]
  },
  pro: {
    items: [
      { description: 'Single user', icon: 'fa-user', class: 'text-bold' },
      {
        description: 'No limits',
        class: 'text-bold text-underline',
        tooltip: 'You have access to all the features'
      },
      { description: 'Sub-assemblies' },
      { description: 'Internal part numbers' },
      {
        description: 'Pricing and availability',
        tooltip:
          'Estimate BOM, purchasing and production costs and check for availability of parts on online suppliers (Mouser, Digikey, etc).<br/><a class="mt-1" href="https://docs.bomist.com/reference/products#pricing--availability" target="_blank">Learn more</a>'
      },
      {
        description: 'Production planning',
        tooltip:
          'Plan production for the whole year if needed and for multiple products which might have parts in common. Check for missing inventory at any time.<br/><a class="mt-1" href="https://docs.bomist.com/reference/products#production-plans" target="_blank">Learn more</a>'
      },
      {
        description: 'Procurement',
        tooltip:
          'Automatically adds missing parts into purchase lists and/or production plans.<br/><a class="mt-1" href="https://docs.bomist.com/features/procurement" target="_blank">Learn more</a>'
      },
      { description: 'Inventory value report' },
      {
        description: 'Custom fields',
        tooltip:
          'Add custom fields to every element on the app.<br/><a class="mt-1" href="https://docs.bomist.com/features/custom-fields" target="_blank">Learn more</a>'
      },
      {
        description: 'Generic parts',
        tooltip:
          'Use generic parts and replace them by one or many that can be used interchangeably during production.'
      },
      {
        description: 'Attrition rate',
        tooltip: 'Account for inventory losses during production.'
      },
      { description: 'Lot control' },
      { description: 'Sales' }
    ]
  },
  team: {
    items: [
      {
        description: 'Unlimited users',
        icon: 'fa-users',
        class: 'text-bold text-underline',
        tooltip:
          'Multiple user can access the same Team Workspace at the same time and see data being changed in real-time'
      },
      {
        description: '1× Team Workspace',
        class: 'text-bold'
      },
      {
        description: 'Role-based permissions',
        tooltip:
          'Assign a role to a team member to set its permissions. Use default roles or create custom ones.<br/><a class="mt-1" href="https://docs.bomist.com/features/role-based-permissions" target="_blank">Learn more</a>'
      },
      {
        description: 'Managed database on the cloud',
        tooltip:
          'Team Workspaces require a central database so data can be synced between team members.<br/><a class="mt-1" href="https://docs.bomist.com/features/workspaces#team-workspaces" target="_blank">Learn more</a>'
      },
      { description: 'Real-time data sync' },
      { description: '5GB document storage' },
      {
        description:
          'Users without a subscription have access to all features on the Team Workspaces they belong to',
        class: 'c-gray4 text-xs'
      }
    ]
  },
  enterprise: {
    items: [
      {
        description: 'On-premises',
        class: 'text-bold text-underline',
        tooltip: 'You decide where and how to host your databases'
      },
      {
        description: 'Offline license',
        class: 'text-bold text-underline',
        tooltip: 'An offline license allows you to be be 100% offline if needed'
      },
      {
        description: 'Priority support',
        tooltip:
          'To make sure everything runs smoothly on your own infrastructure. Higher priority on feature requests as well.'
      },
      {
        description: 'Multiple Team Workspaces',
        tooltip:
          'A single offline license can give you access to multiple Team Workspaces'
      },
      {
        description:
          'Users have access to all features on both Local and Team Workspaces',
        class: 'c-gray4 text-xs'
      }
    ]
  }
};
