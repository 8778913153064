//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    value: { type: Boolean, default: false },
    labelLeft: { type: String, default: '' },
    labelRight: { type: String, default: '' }
  },
  data() {
    return {
      state: this.value // left
    }
  },
  methods: {
    toggle() {
      this.state = !this.state
      this.$emit('toggle', this.state)
    }
  }
}
