var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pricing-cards"},[_c('div',{staticClass:"row no-gutters d-none d-lg-flex justify-content-end"},[_c('div',{style:({ width: 'calc(100% * 3/4)' })},[_vm._m(0)])]),_vm._v(" "),_c('div',{staticClass:"row no-gutters d-flex justify-content-center"},[_c('div',{staticClass:"col-12 col-lg"},[_c('pricing-card',{attrs:{"price":_vm.price('MAKER', 'year'),"price-notes":("<span class='c-gray3'>" + (_vm.price(
            'MAKER',
            'month'
          )) + "</span> per month, billed monthly"),"vatIncluded":true,"show-footer":_vm.showFooter,"native-links":_vm.nativeLinks,"faded":true,"title":"MAKER","subtitle":"For Makers","price-label":"per month, billed yearly","button-text":"Sign Up","button-href":"/sign-up"}},[_c('ul',{staticClass:"text-left"},_vm._l((_vm.pricing['maker'].items),function(item,i){return _c('li',{key:i},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:({
                trigger: 'hover',
                autoHide: false,
                offset: 3,
                popperOptions: {
                  delay: {
                    hide: 500
                  }
                },
                content: item.tooltip
              }),expression:"{\n                trigger: 'hover',\n                autoHide: false,\n                offset: 3,\n                popperOptions: {\n                  delay: {\n                    hide: 500\n                  }\n                },\n                content: item.tooltip\n              }",modifiers:{"right":true}}],class:[
                item.class,
                { 'text-underline-dotted': !!item.tooltip }
              ]},[_vm._v(_vm._s(item.description))]),_vm._v(" "),(!!item.icon)?_c('i',{class:['fa fas ml-2 c-gray2', item.icon]}):_vm._e()])}),0)])],1),_vm._v(" "),_c('div',{staticClass:"col-12 col-lg"},[_c('pricing-card',{attrs:{"price":_vm.price('PRO', 'year'),"price-notes":("<span class='c-gray3'>" + (_vm.price(
            'PRO',
            'month'
          )) + "</span> per month, billed monthly"),"show-footer":_vm.showFooter,"native-links":_vm.nativeLinks,"title":"PRO","subtitle":"For Individuals","price-label":"per month, billed yearly","button-text":"Sign Up","button-href":"/sign-up"}},[_c('template',{slot:"content_header"},[_vm._v("\n          Everything in the "),_c('b',[_vm._v("MAKER")]),_vm._v(" plan, plus:\n        ")]),_vm._v(" "),_c('ul',{staticClass:"text-left"},_vm._l((_vm.pricing['pro'].items),function(item,i){return _c('li',{key:i},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:({
                trigger: 'hover',
                autoHide: false,
                offset: 3,
                popperOptions: {
                  delay: {
                    hide: 500
                  }
                },
                content: item.tooltip
              }),expression:"{\n                trigger: 'hover',\n                autoHide: false,\n                offset: 3,\n                popperOptions: {\n                  delay: {\n                    hide: 500\n                  }\n                },\n                content: item.tooltip\n              }",modifiers:{"right":true}}],class:[
                item.class,
                { 'text-underline-dotted': !!item.tooltip }
              ]},[_vm._v(_vm._s(item.description))]),_vm._v(" "),(!!item.icon)?_c('i',{class:['fa fas ml-2 c-gray2', item.icon]}):_vm._e()])}),0)],2)],1),_vm._v(" "),_c('div',{staticClass:"col-12 col-lg"},[_c('pricing-card',{attrs:{"price":_vm.price('TEAM', 'year'),"price-notes":("<span class='c-gray3'>" + (_vm.price(
            'TEAM',
            'month'
          )) + "</span> per month, billed monthly"),"show-footer":_vm.showFooter,"native-links":_vm.nativeLinks,"title":"TEAM","subtitle":"For Teams","price-label":"per month, billed yearly","button-text":"Sign Up","button-href":"/sign-up","content-header":"Everything in the PRO plan, plus:"}},[_c('template',{slot:"content_header"},[_vm._v("\n          Everything in the "),_c('b',[_vm._v("PRO")]),_vm._v(" plan, plus:\n        ")]),_vm._v(" "),_c('ul',{staticClass:"text-left"},_vm._l((_vm.pricing['team'].items),function(item,i){return _c('li',{key:i},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:({
                trigger: 'hover',
                autoHide: false,
                offset: 3,
                popperOptions: {
                  delay: {
                    hide: 500
                  }
                },
                content: item.tooltip
              }),expression:"{\n                trigger: 'hover',\n                autoHide: false,\n                offset: 3,\n                popperOptions: {\n                  delay: {\n                    hide: 500\n                  }\n                },\n                content: item.tooltip\n              }",modifiers:{"right":true}}],class:[
                item.class,
                { 'text-underline-dotted': !!item.tooltip }
              ]},[_vm._v(_vm._s(item.description))]),_vm._v(" "),(!!item.icon)?_c('i',{class:['fa fas ml-2 c-gray2', item.icon]}):_vm._e()])}),0),_vm._v(" "),_c('template',{slot:"footer-text"})],2)],1),_vm._v(" "),_c('div',{staticClass:"col-12 col-lg"},[_c('pricing-card',{attrs:{"startingAt":true,"price":_vm.price('ENTERPRISE', 'year'),"price-notes":'',"show-footer":_vm.showFooter,"price-label":"per month, billed yearly","title":"ENTERPRISE","subtitle":"For Enterprises","button-text":"Contact Us","button-href":"/support/contact-sales","content-header":""}},[_c('template',{slot:"content_header"},[_c('b',[_vm._v("All the features")]),_vm._v(", plus:\n        ")]),_vm._v(" "),_c('ul',{staticClass:"text-left"},_vm._l((_vm.pricing['enterprise'].items),function(item,i){return _c('li',{key:i},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:({
                trigger: 'hover',
                autoHide: false,
                offset: 3,
                popperOptions: {
                  delay: {
                    hide: 500
                  }
                },
                content: item.tooltip
              }),expression:"{\n                trigger: 'hover',\n                autoHide: false,\n                offset: 3,\n                popperOptions: {\n                  delay: {\n                    hide: 500\n                  }\n                },\n                content: item.tooltip\n              }",modifiers:{"right":true}}],class:[
                item.class,
                { 'text-underline-dotted': !!item.tooltip }
              ]},[_vm._v(_vm._s(item.description))]),_vm._v(" "),(!!item.icon)?_c('i',{class:['fa fas ml-2 c-gray2', item.icon]}):_vm._e()])}),0),_vm._v(" "),_c('template',{slot:"footer-text"},[_c('div',{staticClass:"mb-4"},[_c('span',{staticClass:"d-block text-center text-sm mb-1 c-gray3"},[_vm._v("Need something else?")]),_vm._v(" "),_c('nuxt-link',{staticClass:"btn btn__contact-sales",attrs:{"to":"/support/contact-sales"}},[_vm._v("Contact Sales")])],1)])],2)],1),_vm._v(" "),_c('div',{staticClass:"col-lg-9"})]),_vm._v(" "),_c('div',{staticClass:"d-flex flex-column w-100 align-items-center text-left px-2 text-md-center mt-3"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$inEU()),expression:"$inEU()"}],staticClass:"text-xxs text-faded1"},[_vm._v("\n      Professional plans are VAT excluded whereas the MAKER plan's price\n      already includes VAT.\n    ")]),_vm._v(" "),_c('div',{staticClass:"justify-content-center  text-xxs text-faded1 mt-1",style:({ maxWidth: '680px' })},[_vm._v("\n      Without a subscription, a single user can use the app for FREE, being\n      limited to 100 parts and 3 products.\n    ")])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"banner pro-banner"},[_c('div',{staticClass:"banner__label d-flex justify-content-center text-xs c-purple1"},[_vm._v("\n          PROFESSIONAL PLANS\n        ")])])}]

export { render, staticRenderFns }