//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PricingCard from '@/components/elements/PricingCard.vue';
import ToggleSwitch from '@/components/basics/ToggleSwitch.vue';
import * as api from '@/services/api';

export default {
  components: {
    PricingCard
    // ToggleSwitch
  },
  props: {
    showFooter: { type: Boolean, default: true },
    nativeLinks: { type: Boolean, default: false }
  },
  data() {
    return {
      pricing: require('~/content/raw/pricing.js'),
      billingInterval: 'year', // month, year
      plans: {
        MAKER: [
          {
            amount: 18000,
            interval: 'year',
            currency: 'usd'
          },
          {
            amount: 18000,
            interval: 'year',
            currency: 'eur'
          },
          {
            amount: 1800,
            interval: 'month',
            currency: 'usd'
          },
          {
            amount: 1800,
            interval: 'month',
            currency: 'eur'
          }
        ],
        PRO: [
          {
            amount: 60000,
            interval: 'year',
            currency: 'usd'
          },
          {
            amount: 6000,
            interval: 'month',
            currency: 'usd'
          },
          {
            amount: 54000,
            interval: 'year',
            currency: 'eur'
          },
          {
            amount: 5500,
            interval: 'month',
            currency: 'eur'
          }
        ],
        TEAM: [
          {
            amount: 210000,
            interval: 'year',
            currency: 'usd'
          },
          {
            amount: 22500,
            interval: 'month',
            currency: 'usd'
          },
          {
            amount: 180000,
            interval: 'year',
            currency: 'eur'
          },
          {
            amount: 20000,
            interval: 'month',
            currency: 'eur'
          }
        ],
        ENTERPRISE: [
          { amount: 330000, interval: 'year', currency: 'eur' },
          { amount: 360000, interval: 'year', currency: 'usd' }
        ]
      }
    };
  },
  methods: {
    onToggle(yearly) {
      this.billingInterval = yearly ? 'year' : 'month';
    },
    yearlyAmount(planName) {
      const plan = this.plans[planName].filter(
        (p) => p.currency === this.$currency() && p.interval === 'year'
      )[0];
      return plan.amount;
    },
    monthlyAmount(planName) {
      const plan = this.plans[planName].filter(
        (p) => p.currency === this.$currency() && p.interval === 'month'
      )[0];
      return plan.amount;
    },
    yearlySaving(planName) {
      return 12 * this.monthlyAmount(planName) - this.yearlyAmount(planName);
    },
    savingNotes(planName) {
      return `Save <b>
      ${this.$formatPrice(
        this.yearlySaving(planName)
      )}</b> when billed yearly as <b>${this.$formatPrice(
        this.yearlyAmount(planName)
      )}</b>`;
    },
    price(planName, interval) {
      if (!this.plans) return '';

      const plans = this.plans[planName];
      if (plans) {
        const currency = this.$currency();
        for (const plan of plans) {
          if (plan.interval === interval && plan.currency === currency) {
            return this.$formatBillingPrice(plan.amount, interval);
          }
        }
      }

      return '';
    }
  }
};
