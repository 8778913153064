//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    title: { type: String, required: true },
    subtitle: { type: String, required: true },
    price: { type: String, required: true },
    priceLabel: { type: String, required: true },
    priceNotes: { type: String, default: '' },
    priceMonthly: { type: String, default: '' },
    buttonText: { type: String, required: true },
    buttonHref: { type: String, default: '/sign-up' },
    contentHeader: { type: String, default: '' },
    showFooter: { type: Boolean, default: true },
    nativeLinks: { type: Boolean, default: false },
    faded: { type: Boolean, default: false },
    vatIncluded: { type: Boolean, default: false },
    startingAt: { type: Boolean, default: false }
  },
  computed: {
    cardClasses() {
      return `pricing-card text-center h-100 ${this.faded ? 'faded' : ''}`;
    },
    hasHeaderConent() {
      return this.$slots.header_content;
    },
    hasContentHeader() {
      return this.$slots.content_header;
    }
  }
};
